﻿define([
    'jquery',
    'pubsub',
    'validator',
    'generalUtilities',
    'loginService',
    'messageControl2',
    'mixpanelControl'
], function ($, pubsub, validator, generalUtilities) {
    'use strict';

    var module, elements, values, queryParams;

    module = {};
    module.private = {};

    elements = {
        body: $('body'),
        loginFormSelector: '.js-login-form',
        loginButtonSelector: '.js-login-button',
        ssoSignin: '.js-sso-signin',
        divider: '.js-signin-divider',
        socialSignin: '.js-social-signin'
    };

    values = {

    };

    module.private.deleteCookie = function () {
        document.cookie = 'resaasData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.resaas.com; path=/;';
    }

    module.private.init = function () {
        var $emailField = $('#login-form-email');
        values.queryParams = new URLSearchParams(window.location.search);
        values.em = values.queryParams.get('em');
        values.logout = values.queryParams.get('logout');
        values.ReturnUrl = values.queryParams.get('ReturnUrl');

        if (!generalUtilities.getCookie('ssomigrationcomplete')) {
            $(elements.ssoSignin).show();
            $(elements.divider).show();
            $(elements.socialSignin).show();
        }
          
        $emailField.focus();

        // clear the unbounce pricing cookie in both domain and subdomain
        module.private.deleteCookie();

        // if just logged out clear the mixpanel cookie (generate new distinct_id to avoid account merging)
        if (values.logout === 'true') {
            if (window.history && window.history.replaceState) {
                window.history.replaceState('', '', '/login');
            }
            pubsub.publish('mixpanel.Authentication', { type: 'sign out' });
            RESAAS.MixPanel.cookieClear();
        }

        // populate the email field (invite scenario) TOOD: new route, control etc
        if (values.em) {
            $emailField.val(values.em).attr('readonly', true);
        }
    };

    module.private.handleDataLoginError = function(e, data) {
        var message;

        message =
            data.response && data.response.SignInResult && data.response.SignInResult.Message
                ? data.response.SignInResult.Message
                : RESAAS.Localization.Global.UNABLE_TO_LOG_IN;

        pubsub.publish('uiAction.MessageAdd', {
            message: message,
            type: 'error'
        });
        pubsub.publish('dataLoginFailed');

        //mixpanel
        pubsub.publish('mixpanel.Authentication', { type: 'sign in failed' });

        pubsub.publish('loggly.warning', { message: 'Login failed: ' + JSON.stringify(data) });
    };

    module.private.handleDataLoginSuccess = function(e, data) {
        var goingto, returnUrl;
        
        returnUrl = generalUtilities.prop(data, 'response.SignInResult.Data.ReturnUrl') || '/';
        goingto = ['/redirect/signup?targetUrl=', encodeURIComponent(returnUrl)].join('');

        if (window.Modernizr && window.Modernizr.localstorage) {
            localStorage.setItem('RESAAS.MixPanelValue', 'landOnProfileFromLoginPage');
        }

        // Reset Google Translate
        document.cookie = 'googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'googtrans=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.resaas.com;';

        generalUtilities.redirect(goingto);
    };

    module.private.handleDataLogin = function(e, data) {
        var result = data.response;
        if (
            (result && result.status && result.status !== '200') ||
            (result.SignInResult && !result.SignInResult.Success)
        ) {
            module.private.handleDataLoginError(e, data);
        } else {
            module.private.handleDataLoginSuccess(e, data);
        }
    };

    module.private.getReturnUrl = function() {
        var returnUrl;
        returnUrl = values.ReturnUrl ? values.ReturnUrl : false;

        if (returnUrl && returnUrl !== '') {
            return returnUrl;
        } else {
            return false;
        }
    };

    module.private.prepareCredentials = function(formData) {
        var credentials, returnUrl;

        credentials = {
            credentials: formData
        };
        returnUrl = module.private.getReturnUrl();

        if (returnUrl) {
            credentials.credentials.ReturnUrl = returnUrl;
        }
        return credentials;
    };

    module.private.validateLoginForm = function(formData) {
        var constraints = {
            Email: {
                email: {
                    message: ['^', RESAAS.Localization.Global.SIGNUP2_ERROR_PLZ_ENTER_VALID_EMAIL].join(' ')
                },
                presence: {
                    message: ['^', RESAAS.Localization.Global.SIGNUP2_ERROR_PLZ_ENTER_VALID_EMAIL].join(' ')
                }
            },
            Password: {
                length: {
                    minimum: 6,
                    message: ['^', RESAAS.Localization.Global.SIGNUP2_ERROR_PASSWORD_TOO_SHORT].join(' ')
                },
                presence: {
                    message: ['^', RESAAS.Localization.Global.SIGNUP2_ERROR_PLZ_ENTER_PASSWORD].join(' ')
                }
            }
        };
        return validator.validate(formData, constraints);
    };

    module.private.handleFormSubmit = function(e) {
        var $form, formData, credentials, invalid;
        e.preventDefault();
        $form = $(elements.loginFormSelector);
        formData = validator.serializeObject($form);
        invalid = module.private.validateLoginForm(formData);

        if (!invalid) {
            credentials = module.private.prepareCredentials(formData);
            generalUtilities.disableAndWaitFor(elements.loginButtonSelector, 'dataLoginFailed');
            pubsub.publish('uiHasLogin', credentials);
            pubsub.publish('mixpanel.Intent', { type: 'sign in attempt' });
        } else {
            validator.showErrors($form, invalid);
        }
    };

    //event bindings
    pubsub.subscribe('dataLogin', module.private.handleDataLogin);
    pubsub.subscribe('uiAction.LoginControlInit', module.private.init);

    //element bindings
    elements.body.on('submit', '.js-login-form', module.private.handleFormSubmit);

    return module;
});
